.edit-btn {
    display: none;
}

.edit-parent:hover .edit-btn{
    display: inline-flex;
    cursor: pointer;
    background-color: #D9D9D9;
    width: 30px;
    height: 30px;
}