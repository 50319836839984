/* swiper */
.academySwiper {
  .swiper-pagination-bullet {
    background-color: #28AF6033;
    width: clamp( 10px, 0.8333333333333334vw, 16px);
    height: clamp( 4px, 0.3125vw, 6px);
    border-radius: clamp( 30px, 2.5vw, 48px);
    z-index: 10;
    opacity: 1;
    transition: background-color 0.3s;
  }

  .swiper-pagination-bullet-active {
    background-color: #28AF60;
    width: clamp( 45px, 4.166666666666666vw, 80px);
  }
}

/* academy deatails */
.subscriptionCard:nth-child(1){
  .card-head, button{
    background-color: #28AF60;
  }
}
.subscriptionCard:nth-child(2){
  .card-head, button{
    background-color: #B87333;
  }
}
.subscriptionCard:nth-child(3){
  .card-head, button{
    background-color: #C0C0C0;
  }
}
.subscriptionCard:nth-child(4){
  .card-head, button{
    background-color: #d4ac0b;
  }
}
.subscriptionCard:nth-child(5){
  .card-head, button{
    background-color: #b9f2ff ;
  }
}
.subscriptionCard:nth-child(6){
  .card-head, button{
    background-color: #0F52BA;
  }
}
