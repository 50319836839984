* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* html{
  background-color: #fff !important;
} */

.container {
  width: clamp(340px, 73.61111111111111vw, 1060px) !important;
}

/* slider animation */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

:root {
  --blue-primary: #075178;
  --border: #f1f1f2;
}

.showWelcomeBox {
  animation: slideIn 1s forwards;
}

.hideWelcomeBox {
  animation: slideOut 1s forwards;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.active {
  color: #fff;
  background-color: var(--blue-primary);
  margin: 0 !important;
  border-radius: 5px;
}

.scrollbar-hide {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.main-shadow {
  box-shadow: 0px 3px 4px 0px #00000008;
}
